import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import { Button } from "primereact/button";
import "primeflex/primeflex.css";
import "./UninstallForm.css";
import { InputTextarea } from "primereact/inputtextarea";
import ReactDOM from "react-dom";
import { db } from "../../firebase";

import React, { Component } from "react";
import { Checkbox } from "primereact/checkbox";
export default class UninstallForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      reasons: [],
      comment: "",
    };
    this.validInput = false;
    this.validComment = false;
    this.somethingChecked = false;
    this.onReasonChange = this.onReasonChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  onReasonChange(e) {
    let selectedReasons = [...this.state.reasons];

    if (e.checked) selectedReasons.push(e.value);
    else selectedReasons.splice(selectedReasons.indexOf(e.value), 1);
    console.log(`selectedReasons ${JSON.stringify(selectedReasons)}`);
    this.somethingChecked = selectedReasons.length > 0;
    this.setState({ reasons: selectedReasons });
  }
  submitForm() {
    let reasons = this.state.reasons;
    let comment = this.state.comment;
    console.log(`submitForm:reasons, ${reasons}`);
    console.log(`submitForm:comment, ${comment}`);

    console.log(`submitForm`);
    db.collection("uninstallForm")
      .add({
        reasons: reasons,
        comment: comment,
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((e) => {
        console.log("submit form error", e);
      })
      .finally(() => {
        window.location.href = "/";
      });
  }
  ButtonSwitch = () => {
    return this.somethingChecked || this.validComment ? (
      <React.Fragment>
        <Button
          onClick={this.submitForm}
          label="Submit"
          className="p-button-secondary"
          style={{ width: "267px" }}
        />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Button
          label="Submit"
          className="p-button-outlined p-button-secondary"
          style={{ width: "267px" }}
        />
      </React.Fragment>
    );
  };
  render() {
    return (
      <div>
        <div>
          <h5>Check all that apply.</h5>
          <div className="field-checkbox">
            <Checkbox
              inputId="privacy"
              name="reason"
              value="Privacy"
              onChange={this.onReasonChange}
              checked={this.state.reasons.indexOf("Privacy") !== -1}
            />
            <label htmlFor="privacy">Privacy Concern</label>
          </div>
          <div className="field-checkbox">
            <Checkbox
              inputId="usefull"
              name="reason"
              value="Not Usefull"
              onChange={this.onReasonChange}
              checked={this.state.reasons.indexOf("Not Usefull") !== -1}
            />
            <label htmlFor="usefull">Don't use it</label>
          </div>
          <div className="field-checkbox">
            <Checkbox
              inputId="understanding"
              name="reason"
              value="Lack Understanding"
              onChange={this.onReasonChange}
              checked={this.state.reasons.indexOf("Lack Understanding") !== -1}
            />
            <label htmlFor="understanding">
              Didn't understand how to use it
            </label>
          </div>
          <h5>How else can we improve?</h5>
          <InputTextarea
            value={this.state.comment}
            onChange={(e) => {
              this.setState({ comment: e.target.value });
              this.validComment = e.target.value != "";
            }}
            rows={5}
            cols={30}
          />
        </div>
        <this.ButtonSwitch />
      </div>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<UninstallForm />, rootElement);
